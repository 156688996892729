import React, {useState,useEffect,useRef,useContext} from 'react'
import { GlobalContext } from '../../contexts/GlobalContext';
import { useNavigate, NavLink} from 'react-router-dom';
import MenuItem from './MenuItem';
import DropDown from './DropDown';
import ProfessionalCategoriesMenu from './ProfessionalCategoriesMenu';
import { PiEnvelopeThin,PiPhoneThin } from "react-icons/pi";
import { AiOutlineMenu } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";
import { IoSearchSharp } from "react-icons/io5";
import { TbSettings2, TbUser } from "react-icons/tb";
import {Logo} from "../CoazIcons";
import {menus} from '../../data';
import Login from '../Login';
import AddUserSelf from '../AddUserSelf';
import { request,logout } from '../../App';

const Menu = ({setScroll}) => {
    const {mainElementRef,menuRef,menusParentRef,leftMenusRef,rightMenusRef,screenSize,showDropMainMenu,setShowDropMainMenu,convertToId,hasAnyAuthority,setAccess} = useContext(GlobalContext);
    const [user,setUser] = useState(null);
    const [showCategories,setShowCategories] = useState(false);
    const [closeCategories,setCloseCategories] = useState(true);
    const [dropDown, setDropDown] = useState(null);
    const [profileOpened,setProfileOpened] = useState(false);
    const [settings,setSettings] = useState(null);
    const [admin,setAdmin] = useState(false);
    const menusButtonRef = useRef(null);
    const searchButtonRef = useRef(null);

    const checkMenuYOffset = (showDropMainMenu) => {
        let logo = document.getElementById('logo');
        let logoContainer = document.getElementById('logo-container');
        let shadow = document.getElementById('shadow');
        if(logo && logoContainer && shadow) {
            let p = 'absolute';
            let c = 160;
            let l = 128;
            let bg = 'rgba(238,238,238,0)';
            let textColor = 'white';
            let logoFill = 'white';
            let shadowVisibility = 'hidden';
            let menusTop = 48;
            let menuWidth = menuRef.current.getBoundingClientRect().width;
            let centerX = menuWidth/2;
            if(mainElementRef.current.scrollTop >= 48 && !showDropMainMenu) {
                p = 'fixed';
                c = 96;
                l = 64;
                bg = 'rgba(238,238,238,1)';
                textColor = 'rgb(50,50,50)';
                logoFill = 'rgb(0,175,240)';
                shadowVisibility = 'visible';
                menusTop = 0;
            }
            let logoContainerLeft = centerX-c/2+'px';
            logoContainer.style.position = p;
            logoContainer.style.top = menusTop+'px';
            logoContainer.style.left = logoContainerLeft;
            logoContainer.style.width = c+'px';
            logoContainer.style.height = c+'px';
            logoContainer.style.background = bg;
            logo.style.width = l+'px';
            logo.style.height = l+'px';
            logo.style.fill = logoFill;
            logo.animate([{width:l+'px',height:l+'px',fill:logoFill}],{duration:1000}) 
            if(menusButtonRef.current) {
                menusButtonRef.current.style.position = p;
                menusButtonRef.current.style.top = menusTop+'px';
                menusButtonRef.current.style.height = c+'px';
                menusButtonRef.current.style.width = logoContainerLeft; 
                menusButtonRef.current.style.background = bg;
                menusButtonRef.current.style.color = textColor;
            }
            if(leftMenusRef.current) {
                leftMenusRef.current.style.position = p;
                leftMenusRef.current.style.top = menusTop+'px';
                leftMenusRef.current.style.height = c+'px';
                leftMenusRef.current.style.width = logoContainerLeft; 
                leftMenusRef.current.style.background = bg;
                leftMenusRef.current.style.color = textColor;
            }
            if(searchButtonRef.current) {
                searchButtonRef.current.style.position = p;
                searchButtonRef.current.style.top = menusTop+'px';
                searchButtonRef.current.style.height = c+'px';
                searchButtonRef.current.style.left = centerX+c/2+'px'; 
                searchButtonRef.current.style.width = logoContainerLeft;
                searchButtonRef.current.style.background = bg;
                searchButtonRef.current.style.color = textColor;
            }
            if(rightMenusRef.current) {
                rightMenusRef.current.style.position = p;
                rightMenusRef.current.style.top = menusTop+'px';
                rightMenusRef.current.style.height = c+'px';
                rightMenusRef.current.style.left = centerX+c/2+'px';
                rightMenusRef.current.style.width = logoContainerLeft;
                rightMenusRef.current.style.background = bg;
                rightMenusRef.current.style.color = textColor;
            }
            shadow.style.visibility = shadowVisibility;
            shadow.style.width = menuWidth+'px';
        }
    }

    const openProfile = () => {
        let profile = document.getElementById('profile');
        if(profile) {
            profile.style.top = 40+'px';
            profile.style.height = 'auto';
        }
    }

    const closeProfile = (e) => {
        let profile = document.getElementById('profile');
        if(profile) {
            let rect = profile.getBoundingClientRect();
            profile.style.top = '-'+rect.height+'px';
        }
    }

    const onLogin = (e) => {
        e.preventDefault();
        setAccess({Component:() => <Login reload={load}/>})
    }

    const onLogout = (e) => {
        e.preventDefault();
        closeProfile(e);
        logout();
        load();
    }

    const navigate = useNavigate();

    const load = async () => {
        request('GET','current',null,null,true)
        .then(async (currentResponse) => {
            if(currentResponse.status && currentResponse.status === 'SUCCESSFUL' && currentResponse.content && currentResponse.content.user) {
                setUser(currentResponse.content.user);
            }  else {
                setUser(null);
                logout();
            }
        })
        .catch((error) => {
            console.log(error.message);
            setUser(null);
            logout();
        })
    }

    useEffect(() => {
        checkMenuYOffset(showDropMainMenu);
        setScroll({onScroll:(e,showDropMainMenu) => {
            checkMenuYOffset(showDropMainMenu);
        }});
        load()

        let profile = document.getElementById('profile');
        if(profile) {
            let rect = profile.getBoundingClientRect();
            profile.style.top = '-'+rect.height+'px';
        }

        hasAnyAuthority()
        .then((response) => {
            setAdmin(response);
        })
    },[]);
    let menuIndex = 0;
    return (
        <div ref={menuRef} className='relative flex w-full'>
            <div className='relative flex w-full justify-center md:justify-normal h-12 shrink-0 md:px-24 z-30 bg-[rgb(0,175,240)] shadow-lg'>
                <div className='hidden md:flex flex-row space-x-4 w-full items-center text-sm text-white'>
                    <div className='flex flex-row space-x-2 items-center'>
                        <PiEnvelopeThin size={24}/>
                        <p>coaz@info.org</p>
                    </div>
                    <div className='flex flex-row space-x-2 items-center'>
                        <PiPhoneThin size={24}/>
                        <p>+260971234567</p>
                    </div>
                </div>
                <div className='flex flex-row-reverse space-x-2 space-x-reverse w-auto h-full'>
                    {(user?
                    <div
                        id='profile-btn'
                        onMouseEnter={(e) => {
                            openProfile(e);
                        }}
                        onTouchStart={(e) => {
                            if(profileOpened) {
                                setProfileOpened(false);
                                closeProfile(e);
                            } else {
                                setProfileOpened(true);
                                openProfile(e);
                            }
                        }}
                        onMouseLeave={(e) => {
                            let profileBtn = document.getElementById('profile-btn');
                            if(profileBtn) {
                                let rect = profileBtn.getBoundingClientRect();
                                if(e.clientY < rect.bottom) {
                                    closeProfile(e);
                                }
                            }
                        }}
                        className='flex flex-row space-x-2 w-auto h-auto m-auto items-center cursor-pointer'>
                        <div className='flex w-8 h-8 shrink-0 rounded-full text-[rgb(0,175,240)] bg-white'>
                            <p className='flex w-auto h-auto m-auto font-jostMedium'>{user.firstname.charAt(0)+user.lastname.charAt(0)}</p>
                        </div>
                        <p className='flex w-auto text-sm font-jostBook whitespace-nowrap text-white'>{user.firstname}</p>
                    </div>:
                    <div className='flex flex-row space-x-4 w-auto h-auto m-auto items-center'>
                        {/* <NavLink to={'/access/login'}> */}
                            <button onClick={onLogin} className='w-16 h-8 m-auto text-sm whitespace-nowrap rounded-md bg-white hover:text-[rgb(0,175,240)]'>
                                Login
                            </button>
                        {/* </NavLink> */}
                        {/* <NavLink to={'/access/register'}> */}
                            <button onClick={e => setAccess({Component:() => <AddUserSelf reload={load}/>})} className='w-16 h-8 m-auto text-sm whitespace-nowrap rounded-md text-white hover:bg-white hover:text-[rgb(0,175,240)] border border-white'>
                                Register
                            </button>
                        {/* </NavLink> */}
                    </div>)}
                </div>
            </div>
            <div ref={menusParentRef} className='flex w-full overflow-visible '>
                <div id='shadow' className='fixed top-0 left-0 h-24 z-20 shadow-lg'></div>
                {screenSize === 'lg'?
                    <div ref={leftMenusRef} 
                        style={{transition:'all .5s ease-in-out'}}
                        className='menu-items absolute flex flex-row-reverse items-center space-x-reverse space-x-6 px-4 left-0 shrink-0 z-20'>
                         {menus && (
                            (() => {
                                const menuItems = [];
                                const half = Math.ceil(menus.length/2);
                                menuIndex = half;
                                for(let i = half - 1; i >= 0;i--) {
                                    const menu = menus[i];
                                    if(menu.name.toLowerCase().includes('categories')) {
                                        menuItems.push(<MenuItem key={i} id={convertToId(menu.name)} link={menu.link} name={menu.name} categories={true} setShowCategories={setShowCategories} setCloseCategories={setCloseCategories}/>)
                                    } else {
                                        menuItems.push(<MenuItem key={i} id={convertToId(menu.name)} link={menu.link} name={menu.name} menus={menu.menus} dropDownId={convertToId(menu.name)+'-dropdown'} dropDownComponent={DropDown} setDropDown={setDropDown}/>)
                                    }
                                }
                                
                                return menuItems;
                            })()
                        )} 
                    </div>
                    :
                    <div ref={menusButtonRef} 
                        style={{transition:'all .5s ease-in-out'}}
                        className='menu-items absolute flex items-center justify-center top-0 left-0 shrink-0 z-20'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                if(mainElementRef.current) {
                                    mainElementRef.current.scrollTo({top: 0});
                                }
                                setShowDropMainMenu(!showDropMainMenu);
                            }}
                        >
                            <AiOutlineMenu size={32}/>
                        </button>
                    </div>
                }
                <NavLink to={'/home'}>
                    <button id='logo-container'
                        style={{transition:'all .5s ease-in-out'}} 
                        className='absolute flex items-center justify-center z-20 shrink-0'>
                        <Logo size={128}/>
                    </button>
                </NavLink>
                {screenSize === 'lg'?
                    <div ref={rightMenusRef} 
                        style={{transition:'all .5s ease-in-out'}}
                        className='menu-items absolute hidden lg:flex flex-row items-center space-x-6 px-4 top-0 shrink-0 z-20'>
                        {menus && (
                            (() => {
                                const menuItems = [];
                                const half = Math.ceil(menus.length/2);
                                for(let i = menuIndex; i < menus.length;i++) {
                                    const menu = menus[i];
                                    if(menu.name.toLowerCase().includes('categories')) {
                                        menuItems.push(<MenuItem key={i} id={convertToId(menu.name)} link={menu.link} name={menu.name} categories={true} setShowCategories={setShowCategories} setCloseCategories={setCloseCategories}/>)
                                    } else {
                                        menuItems.push(<MenuItem key={i} id={convertToId(menu.name)} link={menu.link} name={menu.name} menus={menu.menus} dropDownId={convertToId(menu.name)+'-dropdown'} dropDownComponent={DropDown} setDropDown={setDropDown}/>)
                                    }
                                }
                                return menuItems;
                            })()
                        )} 
                        <div className='flex flex-row-reverse items-center space-x-6 pr-24 space-x-reverse w-full h-full'>
                            <NavLink to={''} className={({isActive}) => {return isActive?'text-[rgb(0,175,240)]':''}}>
                                <IoSearchSharp size={20} 
                                style={{transition:'all .3s ease-in-out'}}
                                className='hover:text-[rgb(0,175,240)]'/>
                            </NavLink>
                            {user && settings && (
                                    (() => {
                                        return <MenuItem id={convertToId(settings.name)} link={settings.link} name={settings.name} Icon={TbSettings2} menus={settings.menus} dropDownId={convertToId(settings.name)+'-dropdown'} dropDownComponent={DropDown} setDropDown={setDropDown}/>
                                    })()
                                )
                            }
                        </div>
                    </div>
                    :
                    <div ref={searchButtonRef} 
                        style={{transition:'all .5s ease-in-out'}}
                        className='menu-items absolute flex items-center justify-center top-0 left-0 shrink-0 z-20'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <IoSearchSharp size={32}/>
                        </button>
                    </div>
                }
                {!closeCategories && <ProfessionalCategoriesMenu show={showCategories} setShow={setShowCategories} close={closeCategories} setClose={setCloseCategories}/>}
            </div>         
            <div 
                id='profile' 
                onMouseLeave={(e) => {
                    let profile = document.getElementById('profile');
                    if(profile) {
                        let rect = profile.getBoundingClientRect();
                        profile.style.top = '-'+rect.height+'px';
                    }
                }}
                style={{transition:'all .5s ease-in-out'}}
                className='absolute flex flex-col w-full lg:right-0 lg:w-64 h-0 pt-6 font-jostBook text-[rgb(93,93,93)] bg-[rgb(238,238,238)] z-20 overflow-hidden shadow-lg'
            >
                <div className='flex flex-row w-full h-auto p-4 items-center'>
                   <div className='w-full h-1 border-t border-[rgb(221,221,221)]'/>
                   <div className='flex w-20 h-20 shrink-0 rounded-full bg-[rgb(0,175,240)] border border-[rgb(221,221,221)]'>
                        <p className='flex w-auto h-auto m-auto text-5xl font-jostMedium text-white'>{user?user.firstname.charAt(0)+user.lastname.charAt(0):''}</p>
                   </div>
                   <div className='w-full h-1 border-t border-[rgb(221,221,221)]'/>
                </div>
                <div className='flex flex-col w-full h-auto space-y-2'>
                    <p className='flex w-auto h-auto m-auto text-sm font-jostBook capitalize'>{user?user.firstname+' '+user.lastname:''}</p>
                    <p className='flex w-auto h-auto m-auto text-sm text-[rgb(156,156,156)] font-jostBook lowercase'>{user?user.email:''}</p>
                </div>
                <button onClick={ e => navigate(`/ms/users/${user.id}`)} className='flex flex-row space-x-2 items-center w-full h-10 px-4 mt-4 text-sm hover:text-[rgb(0,175,240)]'>
                    <TbUser size={16}/>
                    <p>My Profile</p>
                </button>
                {admin && 
                    <button onClick={ e => navigate(`/ms/users`)} className='flex flex-row space-x-2 items-center w-full h-10 px-4 text-sm hover:text-[rgb(0,175,240)]'>
                        <TbSettings2 size={16}/>
                        <p>Admin</p>
                    </button>
                }
                <button onClick={onLogout} className='flex flex-row space-x-2 items-center w-full h-10 px-4 text-sm border-t border-[rgb(221,221,221)] hover:text-[rgb(0,175,240)]'>
                    <IoIosLogOut size={16}/>
                    <p>Logout</p>
                </button>
            </div>
        </div>
    )
}

export default Menu