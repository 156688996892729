import React, {useEffect,useState,useContext,useRef} from 'react'
import { GlobalContext } from '../../contexts/GlobalContext';
import { useLocation,useParams,useOutletContext, Outlet } from 'react-router-dom';
import { PiPencilSimple,PiTextAlignLeft,PiChalkboardTeacherFill } from "react-icons/pi";
import EditCourse from './EditCourse';
import AddTopic from './AddTopic';
import TopicItem from './TopicItem';
import MsHeader from './MsHeader';
import Scrollable from '../Scrollable';
import { request } from '../../App';

const CourseClass = () => {
    const {setDialog} = useContext(GlobalContext);
    const [courseClass,setCourseClass] = useState(null);
    const [updateAuthority,setUpdateAuthority] = useState(false)
    const [topics,setTopics] = useState([]);
    const [buttons,setButtons] = useState([]);
    const {courseId,teacherId,topicId} = useParams();
    const {parentPath} = useOutletContext();
    const path = useLocation().pathname;

    const onEdit = (e) => {
        e.preventDefault();
        setDialog({
            show:true,
            Component:() => <EditCourse id={courseId} reload={getCourseClass}/>
        })
    }

    const onAddTopic = (e) => {
        e.preventDefault();
        setDialog({
            show:true,
            Component:() => <AddTopic courseId={courseId} reload={getTopics}/>
        })
    }
    
    const getCourseClass = async () => {
        await request('GET','hasauthority',null,{
            contextName:'COURSE',
            authority:'UPDATE'
        },true)
        .then((response) => {
            if(response.status === 'YES') {
                setButtons([
                    {
                        Icon:PiPencilSimple,
                        name:'Edit',
                        handler:onEdit
                    },
                    {
                        Icon:PiTextAlignLeft,
                        name:'Add Topic',
                        handler:onAddTopic
                    }
                ])
                setUpdateAuthority(true);
            }
        })
        if(courseId && teacherId) {
            await request('GET','class',null,{
                courseId:courseId,
                teacherId:teacherId
            },true)
            .then((response) => {
                if(response.content) {
                    setCourseClass(response.content);
                }  else {
                  setCourseClass(null);
                }
            })
            .catch((error) => {
                setCourseClass(null);
            })
        }
    }

    const getTopics = async () => {
        await request('GET',`topics/${courseId}`,null,null,true)
        .then((response) => {
            if(response.content) {
                setTopics(response.content);
            } else {
                setTopics([]);
            }
        })
        .catch((error) => {
            setTopics([]);
        })
    }

    useEffect(() => {
        getCourseClass();
        getTopics();
    },[path])
  return (
    <>{courseId && teacherId && topicId?
        <Outlet context={{parentPath:`/ms/courses/class/${courseId}/${teacherId}`}}/>:
        <div style={{backgroundSize:304+'px',backgroundImage:'url(/images/home_bg.jpg)'}}
            className='flex flex-col w-full h-full pb-8 space-y-8 items-center overflow-hidden'>
            {courseClass && courseClass.course && courseClass.teacher &&
                <>
                    <MsHeader previous={parentPath} buttons={buttons} Icon={PiChalkboardTeacherFill} text={courseClass.course.name}/>
                    <div className='relative w-[95%] h-full bg-[rgb(255,255,255)] rounded-2xl border border-[rgba(0,175,240,.2)] overflow-hidden p-4'>
                        <Scrollable vertical={true}>
                        {topics && topics.length > 0 &&
                            <div className='flex flex-col w-full h-auto'>
                                <p className='w-full h-6 text-xs font-helveticaNeueRegular tracking-wider text-[rgba(0,175,240,.5)] uppercase'>Topics</p>
                                {topics.map((topic,i) => <TopicItem key={i} topic={topic} reload={getTopics} updateAuthority={updateAuthority}/>)}
                            </div>
                        }
                        </Scrollable>
                    </div>
                </>
            }
        </div>
    }
    </>
  )
}

export default CourseClass