import React, {useEffect,useState, useContext} from 'react';
import { GlobalContext } from '../../contexts/GlobalContext';
import FormValidator, {useFormValidator} from '../FormValidator';
import Inputs from '../Inputs';
import Scrollable from '../Scrollable';
import Message from '../Message';
import FormDialog from '../FormDialog';
import { request } from '../../App';

const EditUserType = ({id,reload}) => {
    const {setLoading,setDialog,setReloadUser} = useContext(GlobalContext);
    const [userType,setUserType] = useState(null);
    const [tariffs,setTariffs] = useState([]);
    const [message,setMessage] = useState({content:'',success:false});
    const minWidth = 240;
    const [inputWidth,setInputWidth] = useState(minWidth);

    const submit = async (e) => {
        setMessage({content:'',success:false});
        setLoading(true);
        request('PUT','usertype',userType,null,true)
        .then((response) => {
            setLoading(false);
            if(response.status) {
                if(response.status === 'SUCCESSFUL' && response.content) {
                    reload && reload();
                    setDialog(null);
                } else {
                    setMessage({content:response.message,success:false});
                }
            } else  {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            setLoading(false);
        });
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);

    const inputs = [
        {
            label:'name',
            type:'text', 
            name:'name',
            value:userType && userType.name?userType.name:'',   
            placeholder:'Enter name...',
            onChange:(e) => {handleChange(e,onChange)},
            register:register,
            errors:errors
        },
        {
            label:'Description',
            type:'text', 
            name:'description',
            value:userType && userType.description?userType.description:'',   
            placeholder:'Enter description...',
            onChange:(e) => {handleChange(e,onChange)}
        },
        {
            label:'Tariff Applicable',
            type:'checkbox',
            name:'tariffApplicable',
            value:userType?userType.tariffApplicable:false,   
            onChange:(e) => {
                setUserType({...userType,tariffApplicable: !userType.tariffApplicable});
            }
        }/* ,
        {
            label:'Reserved',
            type:'checkbox',
            name:'reserved',
            value:userType?userType.reserved:false,   
            onChange:(e) => {
                setUserType({...userType,reserved: !userType.reserved});
            }
        } */
    ]

    const onChange = (e) => {
        const value = e.target.value;
        if(value === '') {
            setUserType({...userType, [e.target.name]: null});
        } else {
            setUserType({...userType, [e.target.name]: value});
        }
    }

    useEffect(() => {
        ( async () => {
            setLoading(true);
            await request('GET',`usertype/${id}`,null,null,true)
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    setUserType(response.content);
                }  else {
                    setUserType(null);
                    setDialog(null);
                }
            })
            .catch((error) => {
                console.log(error.message);
                setLoading(false);
                setUserType(null);
                setDialog(null);
                setReloadUser({reload:true});
            })
        }
        )();
    },[]);

    return (
        <div>
            <FormDialog title='Edit User Type'>
                {userType && <FormValidator>
                    <div className='flex flex-col w-full sm:w-[640px] h-auto p-8'>
                        <Scrollable vertical={true}>
                            <div className='flex flex-col w-full h-auto shrink-0'>
                                <Inputs inputs={inputs} minWidth={minWidth} paddingX={0} spaceX={32} id='add_role' setCalcWidth={setInputWidth}/>
                                <Message message={message}/>
                                <button style={{'--width':inputWidth+'px'}} 
                                    onClick={handleSubmit} className='flex shrink-0 w-[var(--width)] h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                                    Submit
                                </button>
                            </div>
                        </Scrollable>
                    </div>
                </FormValidator>}
            </FormDialog>
        </div>
      )
}

export default EditUserType