import React,{useState,useEffect,useContext} from 'react';
import { GlobalContext } from '../../contexts/GlobalContext';
import { useNavigate,useLocation,Outlet} from 'react-router-dom';
import { PiUser,PiUsersFour, PiUsersThree, PiMaskHappy,PiChartLine,PiGraduationCap, PiBook} from "react-icons/pi";
import MsMenu from './MsMenu';
import MobileMenu from './MobileMenu';
import Login2 from '../Login';
import { request,logout } from '../../App';

const ManagementSystem = () => {
    const path = useLocation().pathname;
    const {setAccess} = useContext(GlobalContext)
    const [user,setUser] = useState(null);
    const [menus,setMenus] = useState([]);
    const [openMobileMenu,setOpenMobileMenu] = useState(false)

    const navigate = useNavigate();

    const onLogin = (e) => {
        e.preventDefault();
        setAccess({Component:() => <Login2 reload={load}/>})
    }

    const onLogout = (e) => {
        logout();
        load();
    }

    const load = async () => {
        let mnus = [];
        await request('GET','current',null,null,true)
        .then(async (currentResponse) => {
            if(currentResponse.status && currentResponse.status === 'SUCCESSFUL' && currentResponse.content && currentResponse.content.user && currentResponse.content.user.status === 'ACTIVE') {
                setUser(currentResponse.content.user);
                mnus.push({name:'Users', link:'/ms/users', Icon:PiUser});
                let createUserType = false;
                let readUserType = false;
                let updateUserType = false;
                let deleteUserType = false;
                await request('GET','hasauthority',null,{
                    contextName:'USERTYPE',
                    authority:'CREATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        createUserType = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'USERTYPE',
                    authority:'READ'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        readUserType = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'USERTYPE',
                    authority:'UPDATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        updateUserType = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'USERTYPE',
                    authority:'DELETE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        deleteUserType = true;
                    }
                })
                if(createUserType || readUserType || updateUserType || deleteUserType) {
                    mnus.push({name:'User types', link:'/ms/usertypes', Icon:PiUsersThree});
                }

                let createUserGroup = false;
                let readUserGroup = false;
                let updateUserGroup = false;
                let deleteUserGroup = false;
                await request('GET','hasauthority',null,{
                    contextName:'USERGROUP',
                    authority:'CREATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        createUserGroup = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'USERGROUP',
                    authority:'READ'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        readUserGroup = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'USERGROUP',
                    authority:'UPDATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        updateUserGroup = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'USERGROUP',
                    authority:'DELETE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        deleteUserGroup = true;
                    }
                })
                if(createUserGroup || readUserGroup || updateUserGroup || deleteUserGroup) {
                    mnus.push({name:'User groups', link:'/ms/usergroups', Icon:PiUsersFour});
                }

                let createRole = false;
                let readRole = false;
                let updateRole = false;
                let deleteRole = false;
                await request('GET','hasauthority',null,{
                    contextName:'ROLE',
                    authority:'CREATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        createRole = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'ROLE',
                    authority:'READ'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        readRole = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'ROLE',
                    authority:'UPDATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        updateRole = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'ROLE',
                    authority:'DELETE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        deleteRole = true;
                    }
                })
                if(createRole || readRole || updateRole || deleteRole) {
                    mnus.push({name:'Roles', link:'/ms/roles', Icon:PiMaskHappy});
                }
                let enroll = false;
                await request('GET','hasauthority',null,{
                    contextName:'PROGRAM',
                    authority:'ENROLL'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        enroll = true;
                        mnus.push({name:'My statistics', link:'/ms/statistics', Icon:PiChartLine})
                    }
                })
                let createProgram = false;
                let updateProgram = false;
                let deleteProgram = false;
                await request('GET','hasauthority',null,{
                    contextName:'PROGRAM',
                    authority:'CREATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        createProgram = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'PROGRAM',
                    authority:'UPDATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        updateProgram = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'PROGRAM',
                    authority:'DELETE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        deleteProgram = true;
                    }
                })
                if(enroll || createProgram || updateProgram || deleteProgram) {
                    mnus.push({name:'Programs', link:'/ms/programs', Icon:PiGraduationCap})
                } 

                let createCourse = false;
                let updateCourse = false;
                let deleteCourse = false;
                let teach = false;
                await request('GET','hasauthority',null,{
                    contextName:'COURSE',
                    authority:'TEACH'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        teach = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'COURSE',
                    authority:'CREATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        createCourse = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'COURSE',
                    authority:'UPDATE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        updateCourse = true;
                    }
                })
                await request('GET','hasauthority',null,{
                    contextName:'COURSE',
                    authority:'DELETE'
                },true)
                .then((response) => {
                    if(response.status && response.status === 'YES') {
                        deleteCourse = true;
                    }
                })
                if(teach || createCourse || updateCourse || deleteCourse) {
                    mnus.push({name:'CPDs', link:'/ms/courses', Icon:PiBook})
                }
            }  else {
                setUser(null);
                logout();
                navigate('/ms/home')
            }
        })
        .catch((error) => {
            console.log(error.message);
            setUser(null);
            logout();
            navigate('/ms/home')
        })
        setMenus(mnus);
    }

    useEffect(() => {
        if(path === '/ms') {
            navigate('/ms/home')
        }
        load();
    },[path]);

  return (
    <div className='flex w-full h-full bg-[url(/public/images/bg_cpd.jpg)] bg-cover bg-center overflow-hidden'>
        <div className='flex flex-col sm:flex-row w-[90%] h-[90%] m-auto shadow-2xl rounded-2xl overflow-hidden'>
            <MsMenu user={user} menus={menus} openMobileMenu={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} onLogin={onLogin} onLogout={onLogout}/>
            <div style={{backgroundSize:304+'px',backgroundImage:'url(/images/home_bg.jpg)'}}
                className='flex flex-col w-full h-full bg-white overflow-hidden'>
                <div style={{transition:'height 1s ease-in-out'}} className={`w-full ${openMobileMenu?'h-full':'h-0'} overflow-hidden`}>
                    <MobileMenu user={user} menus={menus} openMobileMenu={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} onLogin={onLogin} onLogout={onLogout}/>
                </div>
                <div style={{transition:'height 1s ease-in-out'}} className={`w-full ${!openMobileMenu?'h-full':'h-0'} overflow-hidden`}>
                    <Outlet/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ManagementSystem