import React, {useState,useEffect,useContext} from 'react'
import { GlobalContext } from '../../contexts/GlobalContext';
import SubMenu from './SubMenu';
import { LiaAngleRightSolid } from "react-icons/lia";
import { useNavigate,NavLink } from 'react-router-dom';

const DropDownItem = ({menu,show,setShow}) => {
  const {convertToPath} = useContext(GlobalContext);
  const [subMenu, setSubMenu] = useState(null);
  const [showSubMenu,setShowSubMenu] = useState(false);

  const handleMouseEnter = (e) => {
    if(menu && menu.menus) {
      if(!showSubMenu) {
        setShowSubMenu(true);
        setSubMenu({
          id:menu.link+'-submenu',
          component:SubMenu,
          menus:menu.menus
        });
      }
    }
  }

  const handleMouseLeave = (e) => {
    if(menu && menu.menus) {
      let thisMenu = document.getElementById(menu.link);
      if(thisMenu) {
        let rect = thisMenu.getBoundingClientRect();
        if(e.clientX < rect.right) {
          setShowSubMenu(false);
        }
      }
    }
  }

  return (
    <div>
      {menu && menu.menus?
        <div id={menu.link}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleMouseEnter(e);
            }} 
            onMouseEnter={(e) => handleMouseEnter(e)}
            onMouseLeave={(e) => handleMouseLeave(e)}
            className='flex flex-row w-full px-6 justify-between items-center font-jostBook text-[rgb(93,93,93)] hover:text-[rgb(0,175,240)] cursor-pointer'
        >
          <p className='w-full overflow-hidden'>{menu.displayName?menu.displayName:menu.name}</p>
          {menu.menus && <LiaAngleRightSolid size={16} className='flex w-8 shrink-0'/>}
        </div>
        :
        menu && 
          <NavLink to={menu.link?menu.link:''} className={({isActive}) => { return isActive?'text-[rgb(0,175,240)]':'text-[rgb(93,93,93)]'}}>
            <div id={menu.link?menu.link:''}
                onMouseEnter={(e) => handleMouseEnter(e)}
                onMouseLeave={(e) => handleMouseLeave(e)}
                className='flex flex-row w-full px-6 justify-between items-center font-jostBook hover:text-[rgb(0,175,240)] cursor-pointer'
            >
              <p className='w-full overflow-hidden'>{menu.displayName?menu.displayName:menu.name}</p>
            </div>
          </NavLink>
      }
      
      {subMenu && (<subMenu.component show={show} setShow={setShow} showSubMenu={showSubMenu} setShowSubMenu={setShowSubMenu} subMenu={subMenu} setSubMenu={setSubMenu}/>)}
    </div>
  )
}

export default DropDownItem