import React, {useEffect,useState,useContext,useRef} from 'react'
import { GlobalContext } from '../../contexts/GlobalContext';
import { useNavigate,useLocation,useParams, Outlet } from 'react-router-dom';
import { PiTrash,PiDotsThreeVertical,PiUsers,PiUserPlus,PiUserFill,PiCaretDoubleLeft,PiCaretLeft,PiCaretDoubleRight,PiCaretRight,PiFunnel} from "react-icons/pi";
import YesNoDialog from '../YesNoDialog';
import Scrollable from '../Scrollable';
import AddUser from './AddUser';
import MsHeader from './MsHeader';
import AddUsers from './AddUsers';
import Filter from './Filter';
import { request } from '../../App';

const Users = () => {
    const {setDialog,userFilter} = useContext(GlobalContext);
    const [users,setUsers] = useState([]);
    const [readAuthority,setReadAuthority] = useState(false);
    const [deleteAuthority,setDeleteAuthority] = useState(false);
    const {userId} = useParams();
    const [buttons,setButtons] = useState([]);
    const [pageNo,setPageNo] = useState(0);
    const [pageSize,setPageSize] = useState(0);
    const [totalElements,setTotalElements] = useState(0);
    const [totalPages,setTotalPages] = useState(0);
    const [last,setLast] = useState(true);
    const [page,setPage] = useState({
        pageNo:0,
        pageSize:10,
        sortBy:'id',
        sortDir:'asc'
    })
    const path = useLocation().pathname;

    const onAddUser = (e) => {
        setDialog({
            show:true,
            Component:() => 
                <AddUser reload={load}/>
        })
    }

    const onAddUsers = (e) => {
        setDialog({
            show:true,
            Component:() => 
                <AddUsers reload={load}/>
        })
    }

    const onFilter = (e) => {
        setDialog({
            show:true,
            Component:() => 
                <Filter reload={load}/>
        })
    }

    const handleFirst = (e) => {
        if(e) e.preventDefault();
        if(page.pageNo > 0) {
            setPage({...page, pageNo: 0});
        }
    }

    const handlePrevious = (e) => {
        if(e) e.preventDefault();
        if(page.pageNo > 0) {
            setPage({...page, pageNo: page.pageNo - 1});
        }
    }

    const handleNext = (e) => {
        if(e) e.preventDefault();
        if(page.pageNo < totalPages - 1) {
            setPage({...page, pageNo: page.pageNo + 1});
        }
    }

    const handleLast = (e) => {
        if(e) e.preventDefault();
        if(page.pageNo < totalPages - 1 && !last) {
            setPage({...page, pageNo: totalPages - 1});
        }
    }

    const getUsers = async (filter,page) => {
        let current = null;
        
        await request('GET','current',null,null,true)
        .then((response) => {
            if(response.content && response.content.user) {
                current = response.content.user;
            } 
        })
        .catch((error) => {
            console.log(error.message);
        });
        
        await request ('GET','hasauthority',null,{
            contextName:'USER',
            authority:'READ'
        },true)
        .then(async response => {
            if(current && response.status === 'YES') {
                await request('GET','users',null,{
                    username:filter.username,
                    firstname:filter.firstname,
                    lastname:filter.lastname,
                    middlename:filter.middlename,
                    sex:filter.sex,
                    dateOfBirthDay:filter.dateOfBirthDay,
                    dateOfBirthMonth:filter.dateOfBirthMonth,
                    dateOfBirthYear:filter.dateOfBirthYear,
                    idType:filter.idType,
                    idNumber:filter.idNumber,
                    nationality:filter.nationality,
                    email:filter.email,
                    secondaryEmail:filter.secondaryEmail,
                    phone1:filter.phone1,
                    phone2:filter.phone2,
                    physicalAddress:filter.physicalAddress,
                    postalAddress:filter.postalAddress,
                    district:filter.district,
                    program:filter.program,
                    institution:filter.institution,
                    professionalCategory:filter.professionalCategory,
                    employed:filter.employed,
                    employer:filter.employer,
                    organizationalUnit:filter.organizationalUnit,
                    currentPosition:filter.currentPosition,
                    facility:filter.facility,
                    confirmed:filter.confirmed,
                    selfRegistration:filter.selfRegistration,
                    createdOnDay:filter.createdOnDay,
                    createdOnMonth:filter.createdOnMonth,
                    createdOnYear:filter.createdOnYear,
                    status:filter.status,
                    userTypeId:filter.userTypeId,
                    pageNo:page.pageNo,
                    pageSize:page.pageSize,
                    sortBy:page.sortBy,
                    sortDir:page.sortDir},true)
                .then((response) => {
                    if(response.content) {
                        setUsers(response.content);
                        setPageNo(response.pageNo);
                        setPageSize(response.pageSize);
                        setTotalElements(response.totalElements);
                        setTotalPages(response.totalPages);
                        setLast(response.last);
                    } else {
                        setUsers(null);
                    }
                })
                .catch((error) => {
                    setUsers(null);
                });
            } else if(current) {
                setUsers([current])
            } else {
                setUsers(null);
            }
        })
        .catch((error) => {
            setUsers(null);
        })
    }

    const load = async (filter) => {
        let btns = [];
        
        await request('GET','hasauthority',null,{
            contextName:'USER',
            authority:'CREATE'
        },true)
        .then((response) => {
            if(response.status && response.status === 'YES') {
                btns.push(
                    {
                        Icon:PiUserPlus,
                        name:'Add User',
                        handler:onAddUser
                    }
                )
                btns.push(
                    {
                        Icon:PiUsers,
                        name:'Add Users',
                        handler:onAddUsers
                    }
                )
                setReadAuthority(true);
            }
        })
        
        await request('GET','hasauthority',null,{
            contextName:'USER',
            authority:'READ'
        },true)
      
        .then((response) => {
            if(response.status && response.status === 'YES') {
                btns.push(
                    {
                        Icon:PiFunnel,
                        name:'Filter',
                        handler:onFilter
                    }
                )
            }
        })
        
        await request('GET','hasauthority',null,{
            contextName:'USER',
            authority:'DELETE'
        },true)
        .then((response) => {
            if(response.status && response.status === 'YES') {
                setDeleteAuthority(true);
            }
        })
        getUsers(filter,page);
        setButtons(btns);
    }

    useEffect(() => {
        load(userFilter);
    },[path,page])
  return (
    <>{userId?
        <Outlet context={{parentPath:`/ms/users`}}/>:
        <div style={{backgroundSize:304+'px',backgroundImage:'url(/images/home_bg.jpg)'}}
            className='flex flex-col w-full h-full pb-8 space-y-8 items-center overflow-hidden'>
            <MsHeader previous='/ms/home' buttons={buttons} Icon={PiUserFill} text='Users'/>
            <div className='relative w-[95%] h-full bg-[rgb(255,255,255)] rounded-2xl border border-[rgba(0,175,240,.2)] overflow-hidden p-4'>
                <Scrollable vertical={true}>
                    <div className='flex flex-col w-full h-auto space-y-4'>
                        {readAuthority && users  && totalElements >  pageSize &&
                            <div className='flex flex-wrap w-full h-fit justify-between items-center'>
                                <p className='text-xs font-helveticaNeueRegular text-[rgb(145,145,145)] tracking-wider'>
                                    {`Showing ${users && users.length > 0?(pageNo * pageSize)+1:0} to ${((pageNo * pageSize)+users.length)} of ${totalElements}`}
                                </p>
                                <div className='flex flex-row w-fit h-fit space-x-2 text-[rgb(68,71,70)]'> 
                                    <button 
                                        disabled={!pageNo || pageNo < 1}
                                        onClick={handleFirst}
                                        className={`flex w-10 h-10 ${pageNo < 1?'text-[rgb(170,170,170)]':'hover:bg-[rgba(0,0,0,.04)]'} rounded-full`}
                                    >
                                        <PiCaretDoubleLeft size={16} className='flex m-auto'/>
                                    </button>
                                    <button 
                                        disabled={!pageNo || pageNo < 1}
                                        onClick={handlePrevious}
                                        className={`flex w-10 h-10 ${pageNo < 1?'text-[rgb(170,170,170)]':'hover:bg-[rgba(0,0,0,.04)]'} rounded-full`}
                                    >
                                        <PiCaretLeft size={16} className='flex m-auto'/>
                                    </button>
                                    <button 
                                        disabled={last}
                                        onClick={handleNext}
                                        className={`flex w-10 h-10 ${last?'text-[rgb(170,170,170)]':'hover:bg-[rgba(0,0,0,.04)]'} rounded-full`}
                                    >
                                        <PiCaretRight size={16} className='flex m-auto'/>
                                    </button>
                                    <button 
                                        disabled={last}
                                        onClick={handleLast}
                                        className={`flex w-10 h-10 ${last?'text-[rgb(170,170,170)]':'hover:bg-[rgba(0,0,0,.04)]'} rounded-full`}
                                    >
                                        <PiCaretDoubleRight size={16} className='flex m-auto'/>
                                    </button>
                                </div>
                            </div>
                        }
                        {users && users.length > 0 &&
                        <div className='flex flex-col w-full h-auto space-y-2'>
                            {users.map((user,i) => <UserItem key={i} user={user} deleteAuthority={deleteAuthority} reload={load}/>)}
                        </div>
                        }
                    </div>
                </Scrollable>
            </div>
        </div>
    }
    </>
  )
}

export default Users

const UserItem = ({user,deleteAuthority,reload}) => {
    const {setDialog,setPopupData} = useContext(GlobalContext);
    const [highlighted,setHighlighted] = useState(false);
    const [tariff,setTariff] = useState(null);
    const path = useLocation().pathname;
    const moreRef = useRef(null)

    const navigate = useNavigate();

    const onOpen = (e) => {
        e.preventDefault();
        navigate(`/ms/users/${user.id}`)
    }

    const onDelete = (e) => {
        e.preventDefault();
        if(user) {
            setDialog({
                show:true,
                Component:() =>                       
                    <YesNoDialog 
                        title='Delete Program' 
                        message={`Are you sure you want to delete ${user.name}?`} 
                        onYes={async (e) => {
                            
                            await request ('DELETE',`user/${user.id}`,null,null,true)
                            .then(response => {
                                reload && reload({});
                            })
                        }}
                    />
            })
        }
    }

    return (
        <div className='flex flex-row w-full h-auto'>
            {user &&
            <div  onMouseEnter={(e) => setHighlighted(true)} 
                    onMouseLeave={(e) => setHighlighted(false)} 
                    className='flex flex-row w-full p-2 items-center justify-between space-x-4 hover:bg-[rgba(0,0,0,.04)] rounded-md'>
                <div onClick={onOpen}
                    className='flex flex-row w-fit items-center space-x-2 shrink-0 cursor-pointer'>
                    <p className='flex w-10 h-10 shrink-0 rounded-full text-xl font-jostMedium items-center justify-center text-white bg-[rgb(0,175,240)]'>
                        {user.firstname.charAt(0)+user.lastname.charAt(0)}
                    </p>
                    <div className='flex flex-col w-full h-fit'>
                        <p className={`text-sm text-[rgb(68,71,70)] font-helveticaNeueRegular whitespace-nowrap overflow-hidden overflow-ellipsis capitalize`}>
                            {user.name}
                        </p>
                        <div className='flex flex-row space-x-2'>
                            {user.userType &&
                                <p className='text-xs text-[rgb(145,145,145)] font-helveticaNeueRegular whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                    {user.userType.name}
                                </p>
                            }
                            {user.status && <p className={`text-xs ${user.status === 'ACTIVE'?'text-green-600':user.status === 'OTP'?'text-yellow-400':'text-[rgb(145,145,145)]'} font-helveticaNeueRegular whitespace-nowrap overflow-hidden overflow-ellipsis capitalize`}>
                                {user.status.toLowerCase()}
                            </p>}
                        </div>
                    </div>
                </div>
                <div className='flex flex-row w-fit h-10 shrink-0'>
                    {highlighted && deleteAuthority && !user.reserved &&
                        <button ref={moreRef}
                            onClick={(e) => {
                                e.stopPropagation();
                                setPopupData({
                                    show:true,
                                    parentElement:moreRef.current,
                                    Component:
                                        <div className='flex flex-col w-fit h-fit text-left text-sm font-[arial] text-[rgb(68,71,70)]'>
                                            <button 
                                                onClick={onDelete}
                                                className='flex flex-row w-full px-2 h-8 space-x-2 items-center hover:bg-[rgb(234,235,239)] shrink-0'>
                                                <PiTrash size={20} className='flex shrink-0'/>
                                                <p className='w-full text-left whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                                    Delete
                                                </p>
                                            </button>
                                        </div>
                                })
                            }}
                            className='flex w-10 h-10 items-center justify-center shrink-0 hover:bg-[rgba(0,0,0,.06)] rounded-full'>
                            <PiDotsThreeVertical size={20} />
                        </button>
                    }
                </div>
            </div>}
        </div>
    )
}