
import { Routes,Route } from 'react-router-dom';
import React, {useState, useEffect,useRef} from 'react';
import './App.css';
import axios from "axios";
import Main from './components/main-site/Main';
import Home from './components/main-site/Home';
import ProfessionalCategories from './components/main-site/ProfessionalCategories';
import { GlobalContext } from './contexts/GlobalContext';
import Services from './components/main-site/Services';
import Association from './components/main-site/Association';
import About from './components/main-site/About';
import News from './components/main-site/News';
import Organisation from './components/main-site/Organisation';
import Membership from './components/main-site/Membership';
import {organisation,membership} from './data';
import Popup from './components/Popup';
import MsHome from './components/management-system/MsHome';
import ActivityQuestions from './components/management-system/ActivityQuestions';
import EnrollmentAttempt from './components/management-system/EnrollmentAttempt';
import Topic from './components/management-system/Topic';
import Program from './components/management-system/Program';
import Courses from './components/management-system/Courses';
import Course from './components/management-system/Course';
import CourseClass from './components/management-system/CourseClass';
import Enrollment from './components/management-system/Enrollment';
import Attempts from './components/management-system/Attempts';
import MyStatistics from './components/management-system/MyStatistics';
import Programs from './components/management-system/Programs';
import EnrollmentCourse from './components/management-system/EnrollmentCourse';
import EnrollmentTopic from './components/management-system/EnrollmentTopic';
import EnrollmentMaterial from './components/management-system/EnrollmentMaterial';
import ManagementSystem from './components/management-system/ManagementSystem';
import Users from './components/management-system/Users';
import User from './components/management-system/User';
import UserTypes from './components/management-system/UserTypes';
import UserType from './components/management-system/UserType';
import Roles from './components/management-system/Roles';
import Role from './components/management-system/Role';
//import Test from './components/test/Test';
import UserGroups from './components/management-system/UserGroups';
import { PiXLight } from 'react-icons/pi';

//axios.defaults.baseURL = 'http://localhost:8080/api/';
//axios.defaults.baseURL = 'http://192.168.0.161:8080/api/';
//axios.defaults.baseURL = 'https://coaz.org:8085/coaz_test/api/';
axios.defaults.baseURL = 'https://coaz.org:8085/coaz/api/';

export const request = async (method,url,body,params,authorization,refresh) => {
    let response = null;
    switch(method) {
      case 'POST': {
        await axios.post(url, body,{
            params: params,
            headers:authorization?{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}:null
        })
        .then((postResponse) => {
            response = postResponse.data;
        })
        .catch(async (error) => {
            response = error;
        })
        break;
      }
      case 'PUT': {
        await axios.put(url, body,{
          params: params,
          headers:authorization?{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}:null
        })
        .then((putResponse) => {
            response = putResponse.data;
        })
        .catch(async (error) => {
            response = error;
        })
        break;
      }
      case 'GET': {
        await axios.get(url,{
          params: params,
          headers:authorization?{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}:null
        })
        .then((getResponse) => {
          response = getResponse.data;
        })
        .catch(async (error) => {
          response = error;
        })
        break;
      }
      case 'DELETE': {
        await axios.delete(url,{
          params: params,
          headers:authorization?{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}:null
        })
        .then((postResponse) => {
          response = postResponse.data;
        })
        .catch(async (error) => {
          response = error;
        })
        break;
      }
    }
    if(!response.status || response.status === 'ERROR') {
      if(authorization && response.response && response.response.status === 403 && !refresh) {
          await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
          .then(async (refreshResponse) => {
              if(refreshResponse.status === 200) {
                  sessionStorage.setItem("access_token",refreshResponse.data['access_token']);
                  await this.request(method,url,body,params,authorization,true)
                  .then((data) => {
                      response = data;
                  })
                  .catch((error) => {
                      if(error.response && error.response.data && error.response.data.message) {
                          response =  error.response.data.message;
                      } else {
                          response =  error.message;
                      }
                  })
              }
          })
          .catch((error) => {
              if(error.response && error.response.data && error.response.data.message) {
                  response =  error.response.data.message;
              } else if (error.response && error.response.data && error.response.data.error_message) {
                response = error.response.data.error_message;
              }  else if(error.response && error.response.data && error.response.data.trace) {
                response = error.response.data.trace;
              } else if(error.response && error.response.data && error.response.data.error) {
                response = error.response.data.error;
              } else {
                response = error.message;
              }
          }); 
      } else if (response.response && response.response.data && response.response.data.message) {
        response = response.response.data.message;
      } else if (response.response && response.response.data && response.response.data.error_message) {
        response = response.response.data.error_message;
      } else if(response.response && response.response.data && response.response.data.trace) {
        response = response.response.data.trace;
      } else if(response.response && response.response.data && response.response.data.error) {
        response = response.response.data.error;
      } else {
        response = response.message;
      }
    } 
    return response;
}

export const login = async (username,password) => {
    sessionStorage.setItem("access_token",'');
    sessionStorage.setItem("refresh_token",'');
    let responseObject = {};
    await axios.get("login",{params:{username:username,password:password}})
    .then((response) => {
        if(response.data['access_token']) {
            sessionStorage.setItem("access_token",response.data['access_token']);
            sessionStorage.setItem("refresh_token",response.data['refresh_token']);
            responseObject = {status:'SUCCESSFUL'};
        } else {
            responseObject = response.data;
        }
    })
    .catch(async (error) => {
        if(error.response && error.response.status === 401) { 
            if (error.response.data['error_message'] && error.response.data['error_message'].toLowerCase().includes('bad credentials')) {
                responseObject = {error_message:'Incorrect username or password'};
            }else {
                responseObject = error.response.data;
            }
        } else {
            responseObject = {error_message:error.message};
        }
    })

    return responseObject; 
}

export const logout = () => {
    sessionStorage.setItem("access_token",'');
    sessionStorage.setItem("refresh_token",'');
}

export const download = async (url,authorization,refresh) => {
  let response = null;
  await axios.get(url,{
    responseType:'blob',
    headers:authorization?{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}:null
  })
  .then((downloadResponse) => {
      response = downloadResponse.data;
  })
  .catch(async (error) => {
      response = error
  })
  return response;
}

const convertToId = (value) => {
  return value.replace(/ /g,'_').toLowerCase();
}

/* const hasAuthority = (user,authority) => {
  if(!user) {
    return false;
  }
  for(let i = 0; i < user.authorities.length; i++) {
      if(user.authorities[i].authority === authority) {
        return true
      }
    }
  return false;
}  */

const findMenu = (menu,link) => {
  if(menu.link == link) {
    return menu;
  } else if(menu.menus) {
    for(let subMenu of menu.menus) {
      let found = findMenu(subMenu,link);
      if(found) {
        return found;
      }
    }
  }
  return null;
}

const getTextWidth = (text,font,fontSize) => { 
  let span = document.createElement("span"); 
  document.body.appendChild(span); 
  span.style.fontFamily = font; 
  span.style.fontSize = fontSize + "px"; 
  span.style.height = 'auto'; 
  span.style.width = 'auto'; 
  span.style.position = 'absolute'; 
  span.style.whiteSpace = 'no-wrap'; 
  span.innerHTML = text; 

  let width = Math.ceil(span.clientWidth);   
  document.body.removeChild(span); 
  return width;
} 

function App() { 
  const [user,setUser] = useState(null);
  const [userFilter,setUserFilter] = useState({})
  const [reloadUser,setReloadUser] = useState({reload:true});
  const [dialog,setDialog] = useState(null);
  const [screenSize,setScreenSize] = useState('lg');
  const [dropMainMenuIndex,setDropMainMenuIndex] = useState(0);
  const [dropMainMenuIndexs,setDropMainMenuIndexs] = useState([0]);
  const [showDropMainMenu,setShowDropMainMenu] = useState(false);
  const [loading,setLoading] = useState(false);
  const [popupData,setPopupData] = useState({});
  const [access,setAccess] = useState(null);

  const mainElementRef = useRef(null);
  const menuRef = useRef(null);
  const menusParentRef = useRef(null);
  const leftMenusRef = useRef(null);
  const rightMenusRef = useRef(null);

  const createRoute = (menu,Element,key) => {
      let route = null;
      if(menu.menus) {
        route = <Route key={key} path={menu.link} element={<Element/>}>
                    {menu.menus.map((submenu,i) =>
                       createRoute(submenu,Element,key+i)
                    )}
                </Route>
      } else if(menu.itemLink) {
        route = <Route key={key} path={menu.link} element={<Element/>}>
                  <Route key={key+1} path={menu.itemLink} element={<Element/>}/>
              </Route>
      } else {
        route = <Route key={key} path={menu.link} element={<Element/>}/>
      }
      return route;
  }

  const hasAnyAuthority = async () => {
      let anyAuthority = false;
      await request('GET','current',null,null,true)
      .then(async (currentResponse) => {
          if(currentResponse.status && currentResponse.status === 'SUCCESSFUL' && currentResponse.content && currentResponse.content.user) {
              setUser(currentResponse.content.user);
              let createUser = false;
              let readUser = false;
              let updateUser = false;
              let deleteUser = false;
              let userManager = false;
              await request('GET','hasauthority',null,{
                  contextName:'USER',
                  authority:'CREATE'
              },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      createUser = true;
                  }
              })
              await request('GET','hasauthority',null,{
                contextName:'USER',
                authority:'READ'
            },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      readUser = true;
                  }
              })
              await request('GET','hasauthority',null,{
                contextName:'USER',
                authority:'UPDATE'
            },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      updateUser = true;
                  }
              })
              await request('GET','hasauthority',null,{
                contextName:'USER',
                authority:'DELETE'
            },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      deleteUser = true;
                  }
              })
              if(createUser || readUser || updateUser || deleteUser) {
                  userManager = true;
              }

              let createUserType = false;
              let readUserType = false;
              let updateUserType = false;
              let deleteUserType = false;
              let userTypeManager = false;
              await request('GET','hasauthority',null,{
                  contextName:'USERTYPE',
                  authority:'CREATE'
              },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      createUserType = true;
                  }
              })
              await request('GET','hasauthority',null,{
                contextName:'USERTYPE',
                authority:'READ'
            },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      readUserType = true;
                  }
              })
              await request('GET','hasauthority',null,{
                contextName:'USERTYPE',
                authority:'UPDATE'
            },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      updateUserType = true;
                  }
              })
              await request('GET','hasauthority',null,{
                contextName:'USERTYPE',
                authority:'DELETE'
            },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      deleteUserType = true;
                  }
              })
              if(createUserType || readUserType || updateUserType || deleteUserType) {
                  userTypeManager = true;
              }

              let createRole = false;
              let readRole = false;
              let updateRole = false;
              let deleteRole = false;
              let roleManager = false;
              await request('GET','hasauthority',null,{
                contextName:'ROLE',
                authority:'CREATE'
              },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      createRole = true;
                  }
              })
              await request('GET','hasauthority',null,{
                contextName:'ROLE',
                authority:'READ'
              },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      readRole = true;
                  }
              })
              await request('GET','hasauthority',null,{
                contextName:'ROLE',
                authority:'UPDATE'
              },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      updateRole = true;
                  }
              })
              await request('GET','hasauthority',null,{
                contextName:'ROLE',
                authority:'DELETE'
              },true)
              .then((response) => {
                  if(response.status && response.status === 'YES') {
                      deleteRole = true;
                  }
              })
              if(createRole || readRole || updateRole || deleteRole) {
                  roleManager = true;
              }
              if(userManager || userTypeManager || roleManager) {
                anyAuthority = true;
              } else {
                anyAuthority = false;
              }
          }  else {
            anyAuthority = false;
          }
      })

      return anyAuthority;
  }

  useEffect(() => {
      if(reloadUser.reload) {
          setReloadUser({reload:false});
          ( async () => {
              await request('GET','current',null,null,true)
              .then((response) => {
                  if(response.content && response.content.user) {
                      setUser(response.content.user);
                  }  else {
                      setUser(null);
                      logout();
                  }
              })
              .catch((error) => {
                  setUser(null);
                  logout();
              })
          }
          )(); 
      }     
      const observer = new ResizeObserver(entries => {
        for (let entry of entries) {
            let rect = entry.target.getBoundingClientRect();
            if (rect.width > 1024) {
              setScreenSize('lg');
            } else if(rect.width > 640) {
              setScreenSize('sm');
            } else {
              setScreenSize('xs');
            }
        }
      });
      observer.observe(document.documentElement)
      return () => {
        observer.disconnect();
      };
  }, [reloadUser]);

  return (
		<div 
			onClick={(e) => {
				setPopupData(null);
			}} 
			
			className='flex bg-white bg-cover bg-center w-screen h-screen'>
			<GlobalContext.Provider value={{user,mainElementRef,menuRef,menusParentRef,leftMenusRef,rightMenusRef,
				screenSize,reloadUser,setReloadUser,convertToId,dialog,setDialog,
				dropMainMenuIndex,setDropMainMenuIndex,showDropMainMenu,setShowDropMainMenu,
				dropMainMenuIndexs,setDropMainMenuIndexs,findMenu,getTextWidth,loading,setLoading,
				popupData,setPopupData,userFilter,setUserFilter,access,setAccess,request,hasAnyAuthority}}>
        <Routes>
					<Route path='/' element={<Main/>}>
						<Route index element={<Home/>}/>
						<Route path='home' element={<Home/>}/>
						<Route path='association/:duty?' element={<Association/>}/>
						<Route path='services/:service?' element={<Services/>}/>
						<Route path='categories/:category?' element={<ProfessionalCategories/>}/>
						{createRoute(organisation,Organisation)}
						{createRoute(membership,Membership)}
						<Route path='about' element={<About/>}/>
						<Route path='news' element={<News/>}/>
					</Route>
          <Route path='/ms' element={<ManagementSystem/>}>
            <Route path='home/' element={<MsHome/>}/>
            <Route path='users' element={<Users/>}>
              <Route path=':userId' element={<User/>}/>
            </Route>
            <Route path='usertypes' element={<UserTypes/>}>
              <Route path=':userTypeId' element={<UserType/>}/>
            </Route>
            <Route path='usergroups' element={<UserGroups/>}/>
            <Route path='roles' element={<Roles/>}>
              <Route path=':roleId' element={<Role/>}/>
            </Route>
            <Route path='statistics/' element={<MyStatistics/>}/>
            <Route path='programs' element={<Programs/>}>
              <Route path=':programId' element={<Program/>}>
                <Route path=':courseId' element={<Course/>}/>
              </Route>
              <Route path='enrollment/:programId' element={<Enrollment/>}>
                <Route path='class/:courseId' element={<EnrollmentCourse/>}>
                  <Route path=':teacherId/:topicId' element={<EnrollmentTopic/>}>
                    <Route path='attempts/:activityId' element={<EnrollmentMaterial/>}>
                      <Route path=':attemptId' element={<EnrollmentAttempt/>}/>
                    </Route>
                  </Route>
                </Route>
                <Route path=':courseId' element={<Course/>}/>
              </Route>
            </Route>
            <Route path='courses' element={<Courses/>}>
              <Route path=':courseId' element={<Course/>}/>
              <Route path='class/:courseId/:teacherId' element={<CourseClass/>}>
                <Route path=':topicId' element={<Topic/>}>
                  <Route path='questions/:activityId' element={<ActivityQuestions/>}/>
                  <Route path='attempts/:activityId' element={<Attempts/>}>
                    <Route path=':attemptId' element={<EnrollmentAttempt/>}/>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
				</Routes>
				{dialog && dialog.show && dialog.Component && 
				<div className='fixed flex items-center justify-center p-4 w-full h-full overflow-hidden  z-40 bg-[rgba(0,0,0,.5)]'>
						<dialog.Component/>
				</div>
				}
				{access && access.Component &&
				<div style={{backdropFilter:'blur(64px)'}}
            className='fixed flex flex-col w-screen h-screen z-30 bg-[rgba(255,255,255,.2)] overflow-hidden'>
            <div className='flex flex-row-reverse w-full h-16 items-center shrink-0 text-[rgb(68,71,70)]'>
              <button onClick={e => 
                  {
                    setAccess(null);
                  }
                } className='flex w-10 h-10 mr-4 items-center justify-center bg-white rounded-full shadow-md'>
                <PiXLight size={20}/>
              </button>
            </div>
            <div className='flex w-full h-full no-scrollbar overflow-auto items-center justify-center'>
              <access.Component/>
            </div>
				</div>
				}
				{popupData && popupData.show && 
					<Popup/>
				}
			</GlobalContext.Provider>
    </div>
  )
}

export default App;
