import React, {useEffect,useState,useContext} from 'react'
import { GlobalContext } from '../../contexts/GlobalContext';
import { useNavigate,useLocation,useParams,Outlet,useOutletContext } from 'react-router-dom';
import Scrollable from '../Scrollable';
import {PiStudentFill,PiChalkboardTeacherLight, PiBookLight } from "react-icons/pi";
import YesNoDialog from '../YesNoDialog';
import EditProgramCourse from './EditProgramCourse';
import MsHeader from './MsHeader';
import { request } from '../../App';
import Payment from '../Payment';

const Enrollment = () => {
    const {setAccess} = useContext(GlobalContext);
    const [enrollment,setEnrollment] = useState(null);
    const {programId,courseId} = useParams();
    const {parentPath} = useOutletContext();
    const path = useLocation().pathname;

    const navigate = useNavigate();

    const getEnrollment = async () => {
        
        /* await request('GET','hasauthority',null,{
            contextName:'PROGRAM',
            authority:'UPDATE'
        },true)
        .then((response) => {
            if(response.status === 'YES') {
                setUpdateAuthority(true);
            }  else {
                setUpdateAuthority(false);
            }
        }) */

        if(programId) {
            await request('GET','enrollment',null,{
                programId:programId
            },true)
            .then((response) => {
                if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                    if(response.content.paid) {
                        setEnrollment(response.content);
                    } else if(response.content.student && response.content.tariff && response.content.program) {
                        setAccess({Component:() => <Payment user={response.content.student} tariff={response.content.tariff} />});
                        navigate(parentPath);
                    }
                }  else {
                    navigate(parentPath);
                }
            })
            .catch((error) => {
                navigate(parentPath);
            })
        }
    }

    useEffect(() => {
        getEnrollment();
    },[path])
  return (
    <>{courseId? 
            <Outlet context={{parentPath:`/ms/programs/enrollment/${programId}`}}/>
            :
            <div style={{backgroundSize:304+'px',backgroundImage:'url(/images/home_bg.jpg)'}}
                className='flex flex-col w-full h-full pb-8 space-y-8 items-center overflow-hidden'>
                {enrollment && enrollment.program && enrollment.student &&
                    <>
                        <MsHeader previous={parentPath} Icon={PiStudentFill} text={enrollment.program.name} subText={enrollment.program.description}/>
                        <div className='relative w-[95%] h-full bg-[rgb(255,255,255)] rounded-2xl border border-[rgba(0,175,240,.2)] overflow-hidden p-4'>
                            <Scrollable vertical={true}>
                                <div className='flex flex-col w-full h-auto space-y-4'>
                                    {enrollment.enrollmentCourses && enrollment.enrollmentCourses.length > 0 &&
                                    <div className='flex flex-col w-full h-auto space-y-2'>
                                        <p className='w-full h-6 text-xs font-helveticaNeueRegular tracking-wider text-[rgba(0,175,240,.5)] uppercase'>Courses</p>
                                        {enrollment.enrollmentCourses && enrollment.enrollmentCourses.map((enrollmentCourse,i) => <EnrollmentCourseItem key={i} enrollmentCourse={enrollmentCourse} reload={getEnrollment}/>)}
                                    </div>}
                                </div>
                            </Scrollable>
                        </div>
                    </>
                }
            </div>
        }
    </>
  )
}

export default Enrollment

const EnrollmentCourseItem = ({enrollmentCourse,reload}) => {
    const {setDialog} = useContext(GlobalContext);

    const navigate = useNavigate();

    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const onOpen = (e) => {
        e.preventDefault();
        if(enrollmentCourse.programCourse.courseClass) {
            navigate(`/ms/programs/enrollment/${enrollmentCourse.programCourse.programId}/class/${enrollmentCourse.programCourse.courseId}`)
        } else  {
            navigate(`/ms/programs/enrollment/${enrollmentCourse.programCourse.programId}/${enrollmentCourse.programCourse.courseId}`)
        }
    }

    const onEdit = (e) => {
        e.preventDefault();
        if(enrollmentCourse.programCourse && enrollmentCourse.programCourse.programId && enrollmentCourse.programCourse.courseId) {
            setDialog({
                show:true,
                Component:() => <EditProgramCourse programId={enrollmentCourse.programCourse.programId} courseId={enrollmentCourse.programCourse.courseId} reload={reload}/>
            })
        }
    }

    const onRemove = (e) => {
        e.preventDefault();
        if(enrollmentCourse.programCourse) {
            setDialog({
                show:true,
                Component:() => 
                    <YesNoDialog 
                        title='Remove Course' 
                        message={`Are you sure you want to remove ${enrollmentCourse.programCourse.course.name} from ${enrollmentCourse.programCourse.program.name}?`} 
                        onYes={async (e) => {                             
                            await request('DELETE',`programcourse`,null,{
                                programId:enrollmentCourse.programCourse.program.id,
                                courseId:enrollmentCourse.programCourse.course.id
                            },true)
                            .then(response => {
                                reload && reload();
                            })
                        }}
                    />
            })
        }
    }

    useEffect(() => {
        /* ( async () => {
            await request('GET','hasauthority',null,{
                contextName:'PROGRAM',
                authority:'UPDATE'
            },true)
            .then((response) => {
                if(response.status === 'YES') {
                    setUpdateAuthority(true);
                }  else {
                    setUpdateAuthority(false);
                }
            })}
        )(); */
    },[]);

    return (
        <div className='flex flex-row w-full h-auto'>
            {enrollmentCourse && enrollmentCourse.programCourse &&
            <div  /* onMouseEnter={(e) => setHighlighted(true)} 
                onMouseLeave={(e) => setHighlighted(false)} */ 
                className='flex flex-row w-full p-2 items-center justify-between space-x-4 hover:bg-[rgba(0,0,0,.04)] rounded-md'>
                <div onClick={onOpen}
                    className='flex flex-row w-fit items-center space-x-2 shrink-0 cursor-pointer'>
                    {enrollmentCourse.programCourse.courseClass?
                        <PiChalkboardTeacherLight size={40} className='text-[rgb(0,175,240)] shrink-0'/>:
                        <PiBookLight size={40} className='text-[rgb(0,175,240)] shrink-0'/>
                    }
                    <div className='flex flex-col w-full h-fit'>
                        <p className={`text-sm text-[rgb(68,71,70)] font-helveticaNeueRegular whitespace-nowrap overflow-hidden overflow-ellipsis capitalize`}>
                            {`${enrollmentCourse.programCourse.course.name}`}
                        </p>
                        <div className='flex flex-row space-x-1'>
                            <p className={`text-xs text-[rgb(145,145,145)] font-helveticaNeueRegular whitespace-nowrap overflow-hidden overflow-ellipsis capitalize`}>
                                {`${USDecimal.format(enrollmentCourse.programCourse.course.points)} Points`}
                            </p>
                            {enrollmentCourse.programCourse.courseClass &&
                            <>
                                <p className={`text-xs text-[rgb(145,145,145)] font-helveticaNeueRegular whitespace-nowrap overflow-hidden overflow-ellipsis capitalize`}>
                                    {`, ${enrollmentCourse.programCourse.courseClass.teacher.name}`}
                                </p>
                                <p className={`text-xs ${enrollmentCourse.completionPercentage < 100?'text-[rgb(145,145,145)]':'text-green-600'} font-helveticaNeueRegular whitespace-nowrap overflow-hidden overflow-ellipsis capitalize`}>
                                    {`, ${enrollmentCourse.completionPercentage < 100?`${USDecimal.format(enrollmentCourse.completionPercentage)}%`:'Complete'}`}
                                </p>
                            </>
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-row w-fit h-10 shrink-0'>
                    {/* {highlighted && updateAuthority && 
                        <button ref={moreRef}
                            onClick={(e) => {
                                e.stopPropagation();
                                setPopupData({
                                    show:true,
                                    parentElement:moreRef.current,
                                    Component:
                                        <div className='flex flex-col w-fit h-fit text-left text-sm font-[arial] text-[rgb(68,71,70)]'>
                                            <button 
                                                onClick={onEdit}
                                                className='flex flex-row w-full px-2 h-8 space-x-2 items-center hover:bg-[rgb(234,235,239)] shrink-0'>
                                                <PiPen size={20} className='flex shrink-0'/>
                                                <p className='w-full text-sm text-left whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                                    Edit
                                                </p>
                                            </button>
                                            <button 
                                                onClick={onRemove}
                                                className='flex flex-row w-full px-2 h-8 space-x-2 items-center hover:bg-[rgb(234,235,239)] shrink-0'>
                                                <PiTrash size={20} className='flex shrink-0'/>
                                                <p className='w-full text-sm text-left whitespace-nowrap overflow-hidden overflow-ellipsis'>
                                                    Remove
                                                </p>
                                            </button>
                                        </div>
                                })
                            }}
                            className='flex w-10 h-10 items-center justify-center shrink-0 hover:bg-[rgba(0,0,0,.06)] rounded-full'>
                            <PiDotsThreeVertical size={20} />
                        </button>
                    } */}
                </div>
            </div>}
        </div>
    )
}