import React,{useEffect,useState} from 'react'
import {BsGraphUpArrow,BsGraphUp} from "react-icons/bs";
import Scrollable from '../Scrollable';
import { useRef } from 'react';
import { request } from '../../App';

const MyStatistics = () => {
    const [enrollments,setEnrollments] = useState([]);
    const [points,setPoints] = useState(0.0);
    const [completed,setCompleted] = useState(0.0);
    const mainContainerRef = useRef(null);
    const padding = 16;
    const [contentSize,setContentSize] = useState({width:0,height:0});

    useEffect(() => {
        
        request ('GET','enrollments/my',null,null,true)
        .then((response) => {
            if(response.content) {
                let pts = 0;
                let comp = 0;
                for(let enrollment of response.content) {
                    pts += enrollment.points;
                    comp += enrollment.completed;
                }
                setPoints(pts);
                setEnrollments(response.content);
                setCompleted(comp);
            }
        })

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let rect = entry.target.getBoundingClientRect();
                setContentSize({
                    width:rect.width - (padding*2) - 2,
                    height:rect.height - (padding*2) - 2
                })
            }
        });

        if(mainContainerRef.current) {
            observer.observe(mainContainerRef.current)
        }
        return () => {
            observer.disconnect();
        }
    },[])
  return (
    <div className='flex flex-col w-full h-full shrink-0 p-8 space-y-4 tracking-wider text-[rgb(68,71,70)] font-helveticaNeueMedium'>
        <p className='text-sm tracking-wider text-[rgb(68,71,70)] font-helveticaNeueMedium shrink-0 uppercase'>
            My Statistics
        </p>
        <div className='flex flex-wrap w-full h-fit overflow-hidden bg-white shrink-0 border border-[rgba(0,175,240,.2)] rounded-md'>
            <div className='flex flex-row w-fit h-fit items-center space-x-4 p-2 shrink-0'>
                <div className='flex w-10 h-10 items-center justify-center text-[rgb(0,175,240)] bg-[rgba(0,175,240,.2)] shrink-0 rounded-md'>
                    <BsGraphUpArrow size={24}/>
                </div>
                <div className='flex flex-col items-center'>
                    <p className='text-xs text-[rgb(145,145,145)] font-helveticaNeueRegular'>
                        Accumulated Points
                    </p>
                    <p className='w-full font-helveticaNeueMedium text-[rgb(0,175,240)] uppercase'>
                        {points}
                    </p>
                </div>
            </div>
            <div className='flex flex-row w-fit h-fit items-center space-x-4 p-2'>
                <div className='flex w-10 h-10 items-center justify-center text-[rgb(0,175,240)] bg-[rgba(0,175,240,.2)] shrink-0 rounded-md'>
                    <BsGraphUp size={24}/>
                </div>
                <div className='flex flex-col items-center'>
                    <p className='text-xs text-[rgb(145,145,145)] font-helveticaNeueRegular'>
                        Completed CPDs
                    </p>
                    <p className='w-full font-helveticaNeueMedium text-[rgb(0,175,240)] uppercase'>
                        {completed}
                    </p>
                </div>
            </div>
            <div className='flex flex-row w-[20%] h-full'/>
            <div className='flex flex-row w-[20%] h-full'/>
            <div className='flex flex-row w-[20%] h-full'/>
        </div>
        <div ref={mainContainerRef}
            style={{padding:padding+'px'}}
            className='relative flex flex-col w-full h-full bg-white border border-[rgba(0,175,240,.2)] overflow-hidden rounded-md'>
            <Scrollable vertical={true} horizontal={true}>
                <div style={{width:contentSize.width+'px',height:contentSize.height+'px'}}
                    className='relative flex flex-col min-w-[680px]'>
                    <div className='flex flex-row w-full text-sm text-[rgb(68,71,70)] font-helveticaNeueMedium capitalize'>
                        <p className='w-[20%] px-2'>Program</p>
                        <p className='w-[20%] px-2'>CPD</p>
                        <p className='w-[20%] px-2'>Completion</p>
                        <p className='w-[20%] px-2'>Points</p>
                        <p className='w-[20%] px-2'>Awarded Points</p>
                    </div>
                    {enrollments && 
                        enrollments.map((enrollment,i) => {
                        return <>
                            <div key={i} className='w-[20%] p-2'>
                                <p className='w-full p-2 text-xs text-[rgb(0,175,240)] overflow-hidden break-words font-helveticaNeueRegular bg-[rgba(0,175,240,.2)] rounded-md'>
                                    {enrollment.program.name}
                                </p>
                            </div>
                            {enrollment.enrollmentCourses && 
                                enrollment.enrollmentCourses.map((enrollmentCourse,j) => {
                                    return <>
                                        <div key={i+''+j} className='flex flex-row w-full'>
                                            <div className='w-[20%]'/>
                                            <div className='w-[20%] p-2'>
                                                <p className='w-full p-2 text-xs text-[rgb(0,175,240)] font-helveticaNeueRegular bg-[rgba(0,175,240,.2)] rounded-md'>
                                                    {enrollmentCourse.programCourse && enrollmentCourse.programCourse.course?
                                                        enrollmentCourse.programCourse.course.name:''}
                                                </p>
                                            </div>
                                            <div className='flex w-[20%] h-full items-center p-2'>
                                                <div className='relative w-full h-4 bg-gray-200'>
                                                    <div style={{width:enrollmentCourse.completionPercentage+'%'}} className='h-full bg-[rgb(138,209,164)]'/>
                                                    <p className='absolute top-0 left-0 w-full text-xs text-[rgb(68,71,70)] text-center font-helveticaNeueRegular'>
                                                        {`${enrollmentCourse.completionPercentage} %`}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='flex w-[20%] h-full items-center'>
                                                <p className='w-full p-2 text-sm text-[rgb(68,71,70)] font-helveticaNeueRegular'>
                                                    {enrollmentCourse.coursePoints}
                                                </p>
                                            </div>
                                            <div className='flex w-[20%] h-full items-center'>
                                                <p className='w-full p-2 text-sm text-[rgb(68,71,70)] font-helveticaNeueMedium'>
                                                    {enrollmentCourse.points}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                })
                            }
                            {/* <div className='flex flex-row w-full'>
                                <div className='w-[20%]'/>
                                <div className='w-[20%]'/>
                                <div className='w-[20%]'/>
                                <div className='flex w-[20%] h-full items-center'>
                                    <p className='w-full p-2 text-sm text-[rgb(68,71,70)] font-helveticaNeueMedium'>
                                        {enrollment.enrollmentPoints}
                                    </p>
                                </div>
                                <div className='flex w-[20%] h-full items-center'>
                                    <p className='w-full p-2 text-sm text-[rgb(0,175,240)] font-helveticaNeueMedium'>
                                        {enrollment.points}
                                    </p>
                                </div>
                            </div> */}
                        </>
                        })
                    }
                    <div className='absolute flex flex-row top-0 left-0 right-0 bottom-0'>
                        <div className='w-[20%] h-full border-r border-[rgba(0,175,240,.2)]'/>
                        <div className='w-[20%] h-full border-r border-[rgba(0,175,240,.2)]'/>
                        <div className='w-[20%] h-full border-r border-[rgba(0,175,240,.2)]'/>
                        <div className='w-[20%] h-full border-r border-[rgba(0,175,240,.2)]'/>
                        <div className='w-[20%] h-full'/>
                    </div>
                </div>
            </Scrollable>
        </div>
    </div>
  )
}

export default MyStatistics