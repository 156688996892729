import React, {useState} from 'react';
import { useNavigate,NavLink } from 'react-router-dom';

const MenuItem = ({id,link,name,Icon,categories,setShowCategories,setCloseCategories,menus,dropDownId,dropDownComponent}) => {
  const [dropDown, setDropDown] = useState(null);
  const [show,setShow] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = (e) => {
    if(dropDownComponent) {
      if(!show) {
        setShow(true);
        setDropDown({
          id:dropDownId,
          dropDownComponent:dropDownComponent,
          menus:menus
        });
      }
    } else if(categories) {
      setShowCategories(true);
      setCloseCategories(false);
    }
  }

  const handleMouseLeave = (e) => {
    if(dropDownComponent || categories) {
      let thisMenu = document.getElementById(id);
      if(thisMenu) {
        let rect = thisMenu.getBoundingClientRect();
        if(e.clientY < rect.bottom) {
          if(dropDownComponent) {
            setShow(false);
          }
          if(categories) {
            setShowCategories(false);
          }
        }
      }
    } 
  }
 
  return (
    <div onClick={(e)=>setShow(false)} className='relative flex h-full'>
      {menus?
        <button id={id} 
            onClick={(e) => {
              if(!show) {
                  e.preventDefault();
                  e.stopPropagation();
                  handleMouseEnter(e);
              }
            }} 
            onMouseEnter={(e) => handleMouseEnter(e)}
            onMouseLeave={(e) => handleMouseLeave(e)}
            style={{transition:'all .3s ease-in-out'}}
            className='flex items-center h-full font-jostMedium tracking-wide whitespace-nowrap overflow-ellipsis capitalize hover:text-[rgb(0,175,240)]'
        >
            {Icon?<Icon size={20}/>:name}
        </button>:
        <NavLink to={link?link:''} className={({isActive}) => {return isActive?'text-[rgb(0,175,240)]':''}}>
            <button id={id}  
                onMouseEnter={(e) => handleMouseEnter(e)}
                onMouseLeave={(e) => handleMouseLeave(e)}
                style={{transition:'all .3s ease-in-out'}}
                className='flex items-center h-full font-jostMedium tracking-wide whitespace-nowrap overflow-ellipsis capitalize hover:text-[rgb(0,175,240)]'
            >
                {Icon?<Icon size={20}/>:name}
            </button>
        </NavLink>

      }
      
      {dropDown && (<dropDown.dropDownComponent parentDir={id} show={show} setShow={setShow} dropDown={dropDown} setDropDown={setDropDown}/>)}
    </div>
  )
}

export default MenuItem